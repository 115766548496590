<template>
  <div class="register">
    <div class="content" :class="success ? 'scroll-active' : ''">
      <el-card class="card" v-if="success">
        <lottie
          :options="lottieFile"
          class="anim"
          :height="200"
          :width="200"
        ></lottie>

        <h1 v-if="!isMobile">SELAMAT, PENDAFTARAN BERHASIL</h1>
        <h3 v-else>SELAMAT, PENDAFTARAN BERHASIL</h3>
        <p>
          Silahkan lanjutkan dengan membayar biaya registrasi di nomor rekening
          dibawah ini
        </p>
        <ul class="list-info">
          <li>
            <span>Nama Siswa</span>
            <h4>{{ form.nama_lengkap }}</h4>
          </li>
          <li>
            <span>Tingkat Pendidikan</span>
            <h4>{{ tingkatPendidikan[0].text }}</h4>
          </li>
          <li>
            <span>Biaya PPDB</span>
            <h4>{{ formatRupiah(successData.nominal_ppdb) }}</h4>
          </li>
          <li>
            <span>Nomor Virtual Account BNI</span>
            <h4>{{ successData.nomor_virtual_account }}</h4>
          </li>
          <li>
            <span>Tanggal Bayar</span>
            <h4>
              {{
                dayjs(successData.tanggal_bayar_awal).format(formatdate) +
                " - " +
                dayjs(successData.tanggal_bayar_akhir).format(formatdate)
              }}
            </h4>
          </li>
          <li>
            <span>Tanggal Tes</span>
            <h4>{{ dayjs(successData.tanggal_tes).format(formatdate) }}</h4>
          </li>
        </ul>
        <p class="text-left">
          Join group whatsapp kami untuk mendapatkan informasi lainnya dengan
          mengklik tombol
          <b>JOIN WHATSAPP</b>
        </p>

        <el-button-group>
          <el-button type="primary" @click="handleJoin"
            >JOIN WHATSAPP</el-button
          >
          <el-button
            icon="el-icon-download"
            @click="handleDownload(successData.pos)"
            >Format Document</el-button
          >
        </el-button-group>
      </el-card>
      <div class="step-wrapper" v-else>
        <el-steps
          class="step"
          :class="isMobile ? 'mobile' : ''"
          :space="isMobile ? 30 : 200"
          :active="step_active"
          :simple="isMobile ? false : true"
          :direction="isMobile ? 'vertical' : 'horizontal'"
        >
          <el-step title="Data Murid"></el-step>
          <el-step title="Asal Sekolah"></el-step>
          <el-step title="Orang Tua / Wali"></el-step>
        </el-steps>
        <div class="step-content" ref="content">
          <Tip
            :title="
              step_active == 0
                ? 'Data Murid'
                : step_active == 1
                ? 'Asal Sekolah'
                : 'Orang Tua / Wali'
            "
            messages="Pastikan Data yang anda masukkan benar dan bisa dipertanggung jawabkan !"
          />
          <template v-if="step_active == 0">
            <el-form
              class="margin-top-lg"
              label-position="top"
              label-width="250px"
              :model="form"
              :rules="rules"
              ref="muridForm"
            >
              <section>
                <h4 class="form-section-title">Formulir Siswa</h4>
                <el-form-item label="NISN" prop="nisn">
                  <el-input
                    maxlength="10"
                    show-word-limit
                    placeholder="2098172898"
                    v-model="form.nisn"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Siswa" prop="siswa">
                  <el-select
                    class="width-100"
                    v-model="form.siswa"
                    placeholder="Pilih Jenis Siswa"
                  >
                    <el-option value="baru" label="Baru">Baru</el-option>
                    <el-option value="lama" label="Lama">Lama</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Tingkat Pendidikan" prop="lembaga_id">
                  <el-select
                    class="width-100"
                    v-model="form.lembaga_id"
                    placeholder="Pilih Tingkat Pendidikan"
                  >
                    <el-option
                      v-for="item in lembagaBySiswa"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section>
                <h4 class="form-section-title">Identitas Siswa</h4>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="8">
                    <el-form-item
                      label="Foto Siswa"
                      :prop="this.id && this.preview.foto !== '' ? '' : 'foto'"
                    >
                      <div class="upload-wrapper">
                        <el-image
                          fit="contain"
                          class="image-wrapper"
                          :src="preview.foto"
                        >
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                        <input
                          type="file"
                          ref="filePhoto"
                          @change="onPhotoSelected"
                        />
                        <el-button size="small" @click="$refs.filePhoto.click()"
                          >Pilih Foto</el-button
                        >
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="16">
                    <el-form-item label="Nama Lengkap" prop="nama_lengkap">
                      <el-input
                        v-model="form.nama_lengkap"
                        placeholder="Sudibyo Atmojo"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Nama Panggilan" prop="nama_panggilan">
                      <el-input
                        v-model="form.nama_panggilan"
                        placeholder="byo"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item
                  label="No Induk Kependudukan"
                  prop="no_induk_kependudukan"
                >
                  <el-input
                    maxlength="16"
                    show-word-limit
                    v-model="form.no_induk_kependudukan"
                    placeholder="35090928374261"
                  ></el-input>
                </el-form-item>

                <el-row :gutter="12">
                  <el-col :xs="24" :md="8">
                    <el-form-item
                      label="Foto Kartu Keluarga"
                      :prop="this.id && this.preview.kk !== '' ? '' : 'kk'"
                    >
                      <div class="upload-wrapper">
                        <el-image
                          fit="contain"
                          class="image-wrapper"
                          :src="preview.kk"
                        >
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                        <input
                          type="file"
                          ref="fileKK"
                          @change="onKkSelected"
                        />
                        <el-button size="small" @click="$refs.fileKK.click()"
                          >Pilih File</el-button
                        >
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="16">
                    <el-form-item
                      label="No Kartu Keluarga"
                      prop="no_kartu_keluarga"
                    >
                      <el-input
                        maxlength="16"
                        show-word-limit
                        v-model="form.no_kartu_keluarga"
                        placeholder="350909283742776"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item
                  label="Akte Kelahiran"
                  :prop="this.id && this.preview.akte !== '' ? '' : 'akte'"
                >
                  <el-row :gutter="12">
                    <el-col :xs="24" :md="8">
                      <div class="upload-wrapper">
                        <el-image
                          fit="contain"
                          class="image-wrapper"
                          :src="preview.akte"
                        >
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                        <input
                          type="file"
                          ref="fileAkte"
                          @change="onAkteSelected"
                        />
                        <el-button size="small" @click="$refs.fileAkte.click()"
                          >Upload Akte Kelahiran</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="8">
                    <el-form-item label="Tempat Lahir" prop="tempat_lahir">
                      <el-input
                        v-model="form.tempat_lahir"
                        placeholder="Surabaya"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="16">
                    <el-form-item label="Tanggal Lahir" prop="tanggal_lahir">
                      <el-date-picker
                        class="width-100"
                        v-model="form.tanggal_lahir"
                        type="date"
                        placeholder="Pilih Tanggal Lahir"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Jenis Kelamin" prop="jenis_kelamin">
                  <el-select
                    class="width-100"
                    v-model="form.jenis_kelamin"
                    placeholder="Pilih Jenis Kelamin"
                  >
                    <el-option value="laki-laki">Laki - Laki </el-option>
                    <el-option value="perempuan">Perempuan </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Status di Keluarga" prop="status_keluarga">
                  <el-select
                    class="width-100"
                    v-model="form.status_keluarga"
                    placeholder="Pilih Status Keluarga"
                  >
                    <el-option value="kandung">Kandung</el-option>
                    <el-option value="tiri">Tiri</el-option>
                    <el-option value="asuh">Asuh</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Anak Ke (Jika Kandung)" prop="anak_ke">
                  <el-input
                    v-model.number="form.anak_ke"
                    placeholder="1"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Dari Berapa Bersaudara" prop="saudara">
                  <el-input
                    v-model.number="form.saudara"
                    placeholder="2"
                  ></el-input>
                </el-form-item>
                <el-form-item label="No KIP">
                  <el-input v-model="form.no_kip"></el-input>
                </el-form-item>
                <el-form-item label="No BPJS">
                  <el-input v-model="form.no_bpjs"></el-input>
                </el-form-item>
                <el-form-item label="No Jamkes">
                  <el-input v-model="form.no_jamkes"></el-input>
                </el-form-item>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="12">
                    <el-form-item label="Hobi" prop="hobi">
                      <el-select
                        class="width-100"
                        v-model="form.hobi"
                        placeholder="Pilih Hobi"
                      >
                        <el-option value="olahraga">Olahraga</el-option>
                        <el-option value="kesenian">Kesenian</el-option>
                        <el-option value="membaca">Membaca</el-option>
                        <el-option value="menulis">Menulis</el-option>
                        <el-option value="rekreasi">Rekreasi</el-option>
                        <el-option value="lainnya">Lainnya</el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="12">
                    <el-form-item label="Cita-Cita" prop="cita_cita">
                      <el-select
                        class="width-100"
                        v-model="form.cita_cita"
                        placeholder="Pilih Hobi"
                      >
                        <el-option value="wiraswasta">Wiraswasta</el-option>
                        <el-option value="guru/dosen">Guru/Dosen</el-option>
                        <el-option value="dokter">Dokter</el-option>
                        <el-option value="pekerja seni/lukis/artis/sejenis"
                          >Pekerja Seni/Lukis/Artis/Sejenis</el-option
                        >
                        <el-option value="tni/polri">Tni/Polri</el-option>
                        <el-option value="politikus">Politikus</el-option>
                        <el-option value="pns">PNS</el-option>
                        <el-option value="lainnya">Lainnya</el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item
                  label="Berkebutuhan Khusus"
                  prop="kebutuhan_khusus"
                >
                  <el-select
                    class="width-100"
                    v-model="form.kebutuhan_khusus"
                    placeholder="Pilih Hobi"
                  >
                    <el-option
                      v-for="item in kebutuhan_khusus"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </section>
            </el-form>
          </template>
          <template v-if="step_active == 1">
            <el-form
              class="margin-top-lg"
              label-position="top"
              label-width="250px"
              :model="form"
              :rules="rules"
              ref="sekolahForm"
            >
              <section>
                <h4 class="form-section-title">Pendidikan Sebelumnya</h4>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="8">
                    <el-form-item label="Jenis Sekolah" prop="jenis_sekolah">
                      <el-select
                        class="width-100"
                        v-model="form.jenis_sekolah"
                        placeholder="Pilih Jenis Sekolah"
                      >
                        <el-option
                          v-for="item in jenis_sekolah"
                          :key="item.value"
                          :value="item.value"
                          :label="item.text"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="16">
                    <el-form-item label="Nama Sekolah" prop="nama_sekolah">
                      <el-input v-model="form.nama_sekolah"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Status" prop="status">
                  <el-select
                    class="width-100"
                    v-model="form.status"
                    placeholder="Pilih status sekolah"
                  >
                    <el-option
                      v-for="item in status_sekolah"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Alamat" prop="alamat_sekolah">
                  <el-input
                    type="textarea"
                    v-model="form.alamat_sekolah"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Kota/Kabupaten" prop="kota_kabupaten">
                  <el-input v-model="form.kota_kabupaten"></el-input>
                </el-form-item>
                <el-form-item label="NSS/NSM" prop="nss_nsm">
                  <el-input v-model="form.nss_nsm"></el-input>
                </el-form-item>
                <el-form-item label="NPSN Sekolah" prop="npsn_sekolah">
                  <el-input v-model="form.npsn_sekolah"></el-input>
                </el-form-item>
                <el-form-item label="Nomor Seri Ijazah">
                  <el-input v-model="form.seri_ijazah"></el-input>
                </el-form-item>
                <el-form-item label="Nomor Seri SHUN/SKHUN">
                  <el-input v-model="form.seri_shun_skhun"></el-input>
                </el-form-item>
                <el-form-item label="Nomor Peserta Ujian UN">
                  <el-input v-model="form.no_peserta_ujian_un"></el-input>
                </el-form-item>
              </section>
              <section>
                <h4 class="form-section-title">
                  Informasi Nilai Ujian Nasional Siswa
                </h4>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="12">
                    <el-form-item label="Bahasa Indonesia">
                      <el-input
                        type="number"
                        v-model="form.bahasa_indonesia"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="12">
                    <el-form-item label="Matematika">
                      <el-input
                        type="number"
                        v-model="form.matematika"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="12">
                    <el-form-item label="Ilmu Pengetahuan Alam">
                      <el-input type="number" v-model="form.ipa"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="12">
                    <el-form-item label="Bahasa Inggris">
                      <el-input
                        type="number"
                        v-model="form.bahasa_inggris"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="12">
                    <el-form-item label="Rata - Rata">
                      <el-input
                        type="number"
                        v-model="form.rata_rata"
                      ></el-input> </el-form-item
                  ></el-col>
                  <el-col :xs="24" :md="12">
                    <el-form-item label="Jumlah/Danem">
                      <el-input
                        type="number"
                        v-model="form.jumlah_danem"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </section>
            </el-form>
          </template>
          <template v-if="step_active == 2">
            <el-form
              class="margin-top-lg"
              label-position="top"
              label-width="250px"
              :model="form"
              :rules="rules"
              ref="ortuForm"
            >
              <section>
                <h4 class="form-section-title">Identitas Ayah</h4>
                <el-form-item label="Nama Lengkap" prop="ayah.nama_lengkap">
                  <el-input v-model="form.ayah.nama_lengkap"></el-input>
                </el-form-item>
                <el-form-item label="Panggilan" prop="ayah.panggilan">
                  <el-input v-model="form.ayah.panggilan"></el-input>
                </el-form-item>
                <el-form-item label="NIK" prop="ayah.nik">
                  <el-input
                    v-model="form.ayah.nik"
                    maxlength="16"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="10">
                    <el-form-item label="Tempat Lahir" prop="ayah.tempat_lahir">
                      <el-input v-model="form.ayah.tempat_lahir"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="14">
                    <el-form-item
                      label="Tanggal Lahir"
                      prop="ayah.tanggal_lahir"
                    >
                      <el-date-picker
                        class="width-100"
                        v-model="form.ayah.tanggal_lahir"
                        type="date"
                        placeholder="Pilih Tanggal Lahir"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Hubungan Ayah" prop="ayah.hubungan_ayah">
                  <el-select
                    class="width-100"
                    v-model="form.ayah.hubungan_ayah"
                    placeholder="Pilih Hubungan Keluarga"
                  >
                    <el-option
                      v-for="item in hubungan_keluarga"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Status Ayah" prop="ayah.status_ayah">
                  <el-select
                    class="width-100"
                    v-model="form.ayah.status_ayah"
                    placeholder="Pilih Status"
                  >
                    <el-option
                      v-for="item in status"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section>
                <h4 class="form-section-title">Informasi Ayah</h4>
                <el-form-item label="Alamat Lengkap" prop="ayah.alamat_lengkap">
                  <el-input
                    type="textarea"
                    v-model="form.ayah.alamat_lengkap"
                  ></el-input>
                </el-form-item>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="4">
                    <el-form-item label="RT" prop="ayah.rt">
                      <el-input v-model="form.ayah.rt"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="4">
                    <el-form-item label="RW" prop="ayah.rw">
                      <el-input v-model="form.ayah.rw"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="8">
                    <el-form-item
                      label="Desa/Kelurahan"
                      prop="ayah.desa_kelurahan"
                    >
                      <el-input v-model="form.ayah.desa_kelurahan"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="8">
                    <el-form-item label="Kecamatan" prop="ayah.kecamatan">
                      <el-input v-model="form.ayah.kecamatan"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="8">
                    <el-form-item
                      label="Kota/Kabupatan"
                      prop="ayah.kota_kabupaten"
                    >
                      <el-input v-model="form.ayah.kota_kabupaten"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="10">
                    <el-form-item label="Propinsi" prop="ayah.propinsi">
                      <el-input v-model="form.ayah.propinsi"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="6">
                    <el-form-item label="Kode Pos" prop="ayah.kode_pos">
                      <el-input v-model="form.ayah.kode_pos"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item
                  label="Telepon Selular (Whatsapp)"
                  prop="ayah.telepon"
                >
                  <el-input v-model="form.ayah.telepon"></el-input>
                </el-form-item>
              </section>
              <section>
                <h4 class="form-section-title">
                  Pendidikan dan Pekerjaan Ayah
                </h4>
                <el-form-item
                  label="Pendidikan Terakhir"
                  prop="ayah.pendidikan_terakhir"
                >
                  <el-select
                    class="width-100"
                    v-model="form.ayah.pendidikan_terakhir"
                    placeholder="Pilih Pendidikan"
                  >
                    <el-option
                      v-for="item in pendidikan"
                      :key="item.value"
                      :value="item.value"
                      :label="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Pekerjaan" prop="ayah.pekerjaan">
                  <el-select
                    class="width-100"
                    v-model="form.ayah.pekerjaan"
                    placeholder="Pilih Pekerjaan"
                  >
                    <el-option
                      v-for="item in pekerjaan"
                      :key="item.value"
                      :value="item.value"
                      :label="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Penghasilan" prop="ayah.penghasilan">
                  <el-select
                    class="width-100"
                    v-model="form.ayah.penghasilan"
                    placeholder="Pilih Penghasilan Ayah"
                  >
                    <el-option
                      v-for="item in penghasilan"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section>
                <h4 class="form-section-title">Identitas Ibu</h4>
                <el-form-item label="Nama Lengkap" prop="ibu.nama_lengkap">
                  <el-input v-model="form.ibu.nama_lengkap"></el-input>
                </el-form-item>
                <el-form-item label="Panggilan" prop="ibu.panggilan">
                  <el-input v-model="form.ibu.panggilan"></el-input>
                </el-form-item>
                <el-form-item label="NIK" prop="ibu.nik">
                  <el-input
                    v-model="form.ibu.nik"
                    maxlength="16"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="10">
                    <el-form-item label="Tempat Lahir" prop="ibu.tempat_lahir">
                      <el-input v-model="form.ibu.tempat_lahir"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="14">
                    <el-form-item
                      label="Tanggal Lahir"
                      prop="ibu.tanggal_lahir"
                    >
                      <el-date-picker
                        class="width-100"
                        v-model="form.ibu.tanggal_lahir"
                        type="date"
                        placeholder="Pilih Tanggal Lahir"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Hubungan Ibu" prop="ibu.hubungan_ibu">
                  <el-select
                    class="width-100"
                    v-model="form.ibu.hubungan_ibu"
                    placeholder="Pilih Hubungan Keluarga"
                  >
                    <el-option
                      v-for="item in hubungan_keluarga"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Status Ibu" prop="ibu.status_ibu">
                  <el-select
                    class="width-100"
                    v-model="form.ibu.status_ibu"
                    placeholder="Pilih Status"
                  >
                    <el-option
                      v-for="item in status"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section>
                <h4 class="form-section-title">Informasi Ibu</h4>
                <el-form-item label="Alamat Lengkap">
                  <el-input
                    type="textarea"
                    v-model="form.ibu.alamat_lengkap"
                  ></el-input>
                </el-form-item>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="4">
                    <el-form-item label="RT">
                      <el-input v-model="form.ibu.rt"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="4">
                    <el-form-item label="RW">
                      <el-input v-model="form.ibu.rw"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="8">
                    <el-form-item label="Desa/Kelurahan">
                      <el-input v-model="form.ibu.desa_kelurahan"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="8">
                    <el-form-item label="Kecamatan">
                      <el-input v-model="form.ibu.kecamatan"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="8">
                    <el-form-item label="Kota/Kabupatan">
                      <el-input v-model="form.ibu.kota_kabupaten"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="10">
                    <el-form-item label="Propinsi">
                      <el-input v-model="form.ibu.propinsi"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="6">
                    <el-form-item label="Kode Pos">
                      <el-input v-model="form.ibu.kode_pos"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item
                  label="Telepon Selular (Whatsapp)"
                  prop="ibu.telepon"
                >
                  <el-input v-model="form.ibu.telepon"></el-input>
                </el-form-item>
              </section>
              <section>
                <h4 class="form-section-title">Pendidikan dan Pekerjaan Ibu</h4>
                <el-form-item
                  label="Pendidikan Terakhir"
                  prop="ibu.pendidikan_terakhir"
                >
                  <el-select
                    class="width-100"
                    v-model="form.ibu.pendidikan_terakhir"
                    placeholder="Pilih Pendidikan"
                  >
                    <el-option
                      v-for="item in pendidikan"
                      :key="item.value"
                      :value="item.value"
                      :label="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Pekerjaan" prop="ibu.pekerjaan">
                  <el-select
                    class="width-100"
                    v-model="form.ibu.pekerjaan"
                    placeholder="Pilih Pekerjaan"
                  >
                    <el-option
                      v-for="item in pekerjaan"
                      :key="item.value"
                      :value="item.value"
                      :label="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Penghasilan" prop="ibu.penghasilan">
                  <el-select
                    class="width-100"
                    v-model="form.ibu.penghasilan"
                    placeholder="Pilih Penghasilan Ibu"
                  >
                    <el-option
                      v-for="item in penghasilan"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section>
                <div class="section-title">
                  <h4 class="form-section-title">Identitas Wali</h4>
                  <small
                    >( orang yang bertanggung jawab atas segala urusan santri
                    )</small
                  >
                </div>
                <el-form-item label="Nama Lengkap">
                  <el-input v-model="form.wali.nama_lengkap"></el-input>
                </el-form-item>
                <el-form-item label="Panggilan">
                  <el-input v-model="form.wali.panggilan"></el-input>
                </el-form-item>
                <el-form-item label="NIK" prop="wali.nik">
                  <el-input
                    v-model="form.wali.nik"
                    maxlength="16"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="10">
                    <el-form-item label="Tempat Lahir">
                      <el-input v-model="form.wali.tempat_lahir"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="14">
                    <el-form-item label="Tanggal Lahir">
                      <el-date-picker
                        class="width-100"
                        v-model="form.wali.tanggal_lahir"
                        type="date"
                        placeholder="Pilih Tanggal Lahir"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Hubungan Wali">
                  <el-select
                    class="width-100"
                    v-model="form.wali.hubungan_wali"
                    placeholder="Pilih Hubungan Keluarga"
                  >
                    <el-option
                      v-for="item in hubungan_keluarga"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Status Wali">
                  <el-select
                    class="width-100"
                    v-model="form.wali.status_wali"
                    placeholder="Pilih Hubungan Keluarga"
                  >
                    <el-option
                      v-for="item in status"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section>
                <h4 class="form-section-title">Informasi Wali</h4>
                <el-form-item label="Alamat Lengkap">
                  <el-input
                    type="textarea"
                    v-model="form.wali.alamat_lengkap"
                  ></el-input>
                </el-form-item>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="4">
                    <el-form-item label="RT">
                      <el-input v-model="form.wali.rt"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="4">
                    <el-form-item label="RW">
                      <el-input v-model="form.wali.rw"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="8">
                    <el-form-item label="Desa/Kelurahan">
                      <el-input v-model="form.wali.desa_kelurahan"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="8">
                    <el-form-item label="Kecamatan">
                      <el-input v-model="form.wali.kecamatan"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="12">
                  <el-col :xs="24" :md="8">
                    <el-form-item label="Kota/Kabupatan">
                      <el-input v-model="form.wali.kota_kabupaten"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="10">
                    <el-form-item label="Propinsi">
                      <el-input v-model="form.wali.propinsi"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="6">
                    <el-form-item label="Kode Pos">
                      <el-input v-model="form.wali.kode_pos"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Telepon Selular (Whatsapp)">
                  <el-input v-model="form.wali.telepon"></el-input>
                </el-form-item>
              </section>
              <section>
                <h4 class="form-section-title">
                  Pendidikan dan Pekerjaan Wali
                </h4>
                <el-form-item label="Pendidikan Terakhir">
                  <el-select
                    class="width-100"
                    v-model="form.wali.pendidikan_terakhir"
                    placeholder="Pilih Pendidikan"
                  >
                    <el-option
                      v-for="item in pendidikan"
                      :key="item.value"
                      :value="item.value"
                      :label="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Pekerjaan">
                  <el-select
                    class="width-100"
                    v-model="form.wali.pekerjaan"
                    placeholder="Pilih Pekerjaan"
                  >
                    <el-option
                      v-for="item in pekerjaan"
                      :key="item.value"
                      :value="item.value"
                      :label="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Penghasilan">
                  <el-select
                    class="width-100"
                    v-model="form.wali.penghasilan"
                    placeholder="Pilih Penghasilan Wali"
                  >
                    <el-option
                      v-for="item in penghasilan"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </section>
            </el-form>
          </template>
        </div>
        <div class="step-footer">
          <el-button
            v-if="step_active < step_total"
            size="medium"
            type="primary"
            @click="handleNext()"
            >Next</el-button
          >
          <el-button
            v-else-if="step_active == step_total"
            size="medium"
            type="primary"
            :loading="loading"
            @click="handleSubmit()"
            v-loading.fullscreen.lock="loading"
            >Submit</el-button
          >
          <el-button
            @click="handlePrevious()"
            v-if="step_active > 0"
            size="medium"
            >Previous</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tip from "@/components/tip.vue";
import lottie from "vue-lottie";
import success from "@/assets/animation/success.json";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "register",
  props: {
    id: { type: String },
  },
  components: {
    Tip,
    lottie: lottie,
  },
  watch: {
    "form.siswa": function () {
      console.log("handlebysiswa");
      this.handleBySiswa();
    },
  },
  computed: {
    ...mapGetters({
      token: "getToken",
      initial: "getSelectedRiwayat",
      riwayat: "getRiwayat",
    }),
    tingkatPendidikan() {
      return this.lembaga.filter((obj) => obj.value == this.form.lembaga_id);
    },
  },
  data() {
    const kebutuhan_khusus = [
      {
        text: "Tidak ada",
        value: "tidak ada",
      },
      {
        text: "Tuna Rungu",
        value: "tuna rungu",
      },
      {
        text: "Tuna Netra",
        value: "tuna netra",
      },
      {
        text: "Tuna Daksa",
        value: "tuna daksa",
      },
      {
        text: "Tuna Grahita",
        value: "tuna grahita",
      },
      {
        text: "Tuna Laras",
        value: "tuna laras",
      },
      {
        text: "Lamban Belajar",
        value: "lamban belajar",
      },
      {
        text: "Sulit Belajar",
        value: "sulit belajar",
      },
      {
        text: "Gangguan Komunikasi",
        value: "gangguan komunikasi",
      },
      {
        text: "Bakat Luar Biasa",
        value: "bakat luar biasa",
      },
    ];
    const jenis_sekolah = [
      {
        text: "SD",
        value: "SD",
      },
      {
        text: "MI",
        value: "MI",
      },
      {
        text: "Paket A",
        value: "Paket A",
      },
      {
        text: "SMP",
        value: "SMP",
      },
      {
        text: "MTS",
        value: "MTS",
      },
      {
        text: "Paket B",
        value: "Paket B",
      },
      {
        text: "SMA",
        value: "SMA",
      },
      {
        text: "SMK",
        value: "SMK",
      },
      {
        text: "MA",
        value: "MA",
      },
      {
        text: "PAKET C",
        value: "PAKET C",
      },
    ];
    const status_sekolah = [
      {
        text: "Negeri",
        value: "negeri",
      },
      {
        text: "Swasta",
        value: "swasta",
      },
    ];
    const hubungan_keluarga = [
      {
        text: "Kandung",
        value: "kandung",
      },
      {
        text: "Tiri",
        value: "tiri",
      },
      {
        text: "Angkat",
        value: "angkat",
      },
    ];
    const status = [
      {
        text: "Hidup",
        value: "hidup",
      },
      {
        text: "Mati",
        value: "mati",
      },
    ];
    const penghasilan = [
      {
        text: "< Rp. 1.000.000",
        value: "< Rp. 1.000.000",
      },
      {
        text: "Rp. 1.000.000 - Rp. 2.000.000",
        value: "Rp. 1.000.000 - Rp. 2.000.000",
      },
      {
        text: "Rp. 2.000.000 - Rp. 3.000.000",
        value: "Rp. 2.000.000 - Rp. 3.000.000",
      },
      {
        text: "Rp. 4.000.000 - Rp. 5.000.000",
        value: "Rp. 4.000.000 - Rp. 5.000.000",
      },
      {
        text: "> Rp. 5.000.000",
        value: "> Rp. 5.000.000",
      },
      {
        text: "> Rp. 10.000.000",
        value: "> Rp. 10.000.000",
      },
    ];
    const pendidikan = [
      {
        value: "Tidak Berpendidikan",
      },
      {
        value: "SMP/MTs/SLTP",
      },
      {
        value: "SMA/MA/SMK/SLTA",
      },
      {
        value: "D1",
      },
      {
        value: "D2",
      },
      {
        value: "D3",
      },
      {
        value: "D4",
      },
      {
        value: "S1",
      },
      {
        value: "S2",
      },
      {
        value: "S3",
      },
    ];
    const pekerjaan = [
      {
        value: "Tidak Bekerja",
      },
      {
        value: "PNS",
      },
      {
        value: "Pensiunan",
      },
      {
        value: "TNI/POLRI",
      },
      {
        value: "Guru/Dosen",
      },
      {
        value: "Pegawai Swasta",
      },
      {
        value: "Pengusaha",
      },
      {
        value: "Pengacara/Notaris/Jaksa",
      },
      {
        value: "Seniman",
      },
      {
        value: "Dokter/Bidan/Perawat",
      },
      {
        value: "Pilot/Pramugari",
      },
      {
        value: "Pedagang",
      },
      {
        value: "Petani/Peternak",
      },
      {
        value: "Nelayan",
      },
      {
        value: "Buruh Tani",
      },
      {
        value: "Sopir/Masinis",
      },
      {
        value: "Politikus",
      },
      {
        value: "Lainnya",
      },
    ];

    return {
      kebutuhan_khusus,
      jenis_sekolah,
      status_sekolah,
      status,
      hubungan_keluarga,
      penghasilan,
      pendidikan,
      pekerjaan,
      lottieFile: { animationData: success, loop: true },
      lembagaBySiswa: [],
      preview: {
        foto: "",
        kk: "",
        akte: "",
      },
      formatdate: "DD MMMM YYYY",
      formatdatedb: "YYYY-MM-DD",
      loading: false,
      form: {
        nisn: "",
        siswa: "",
        lembaga_id: null,
        kebutuhan_khusus: "",
        cita_cita: "",
        hobi: "",
        foto: null,
        no_jamkes: "",
        no_bpjs: "",
        no_kip: "",
        saudara: "",
        anak_ke: "",
        akte_kelahiran: null,
        status_keluarga: "",
        jenis_kelamin: "",
        tanggal_lahir: "",
        tempat_lahir: "",
        kk: null,
        akte: null,
        no_kartu_keluarga: "",
        no_induk_kependudukan: "",
        nama_panggilan: "",
        nama_lengkap: "",
        jenis_sekolah: null,
        nama_sekolah: null,
        status: "",
        alamat_sekolah: "",
        kota_kabupaten: "",
        nss_nsm: "",
        npsn_sekolah: "",
        seri_ijazah: "",
        seri_shun_skhun: "",
        no_peserta_ujian_un: "",
        bahasa_indonesia: 0,
        matematika: 0,
        ipa: 0,
        bahasa_inggris: 0,
        rata_rata: 0,
        jumlah_danem: 0,
        ayah: {
          nama_lengkap: "",
          panggilan: "",
          nik: "",
          tempat_lahir: "",
          tanggal_lahir: "",
          hubungan_ayah: "",
          status_ayah: "",
          alamat_lengkap: "",
          rt: "",
          rw: "",
          desa_kelurahan: "",
          kecamatan: "",
          kota_kabupaten: "",
          propinsi: "",
          kode_pos: "",
          telepon: "",
          pendidikan_terakhir: "",
          pekerjaan: "",
          penghasilan: "",
        },
        ibu: {
          nama_lengkap: "",
          panggilan: "",
          nik: "",
          tempat_lahir: "",
          tanggal_lahir: "",
          hubungan_ibu: "",
          status_ibu: "",
          alamat_lengkap: "",
          rt: "",
          rw: "",
          desa_kelurahan: "",
          kecamatan: "",
          kota_kabupaten: "",
          propinsi: "",
          kode_pos: "",
          telepon: "",
          pendidikan_terakhir: "",
          pekerjaan: "",
          penghasilan: "",
        },
        wali: {
          nama_lengkap: "",
          panggilan: "",
          nik: "",
          tempat_lahir: "",
          tanggal_lahir: "",
          hubungan_wali: "",
          status_wali: "",
          alamat_lengkap: "",
          rt: "",
          rw: "",
          desa_kelurahan: "",
          kecamatan: "",
          kota_kabupaten: "",
          propinsi: "",
          kode_pos: "",
          telepon: "",
          pendidikan_terakhir: "",
          pekerjaan: "",
          penghasilan: "",
        },
      },
      rules: {
        lembaga_id: [{ required: true }],
        kebutuhan_khusus: [{ required: true }],
        cita_cita: [{ required: true }],
        hobi: [{ required: true }],
        saudara: [{ required: true, type: "number" }],
        anak_ke: [{ required: true, type: "number" }],
        status_keluarga: [{ required: true }],
        jenis_kelamin: [{ required: true }],
        tanggal_lahir: [{ required: true }],
        tempat_lahir: [{ required: true }],
        no_kartu_keluarga: [
          { required: true },
          {
            pattern: /(^[0-9]*$)/,
            message: "Isi angka saja",
            trigger: "blur",
          },
          {
            min: 16,
            max: 16,
            message: "Panjang No KTP anda kurang",
            trigger: "blur",
          },
        ],
        no_induk_kependudukan: [
          {
            pattern: /(^[0-9]*$)/,
            message: "Isi angka saja",
            trigger: "blur",
          },
          {
            required: true,
            min: 16,
            max: 16,
            message: "Panjang No KTP anda kurang",
            trigger: "blur",
          },
        ],
        nama_panggilan: [{ required: true }],
        nama_lengkap: [{ required: true }],
        nisn: [
          {
            required: true,
            pattern: /(^[0-9]{10}$)/,
            message: "Isi angka saja dengan panjang 10 angka",
            trigger: "blur",
          },
          {
            min: 10,
            max: 10,
            message: "Isi angka saja dengan panjang 10 angka",
            trigger: "blur",
          },
        ],
        siswa: [{ required: true }],
        foto: [{ required: true }],
        kk: [{ required: true }],
        akte: [{ required: true }],
        jenis_sekolah: [{ required: true, trigger: "change" }],
        nama_sekolah: [{ required: true, trigger: "blur" }],
        status: [{ required: true }],
        alamat_sekolah: [{ required: true, trigger: "blur" }],
        kota_kabupaten: [{ required: true }],
        nss_nsm: [{ required: true }],
        npsn_sekolah: [{ required: true }],
        seri_ijazah: [{ required: true }],
        seri_shun_skhun: [{ required: true }],
        no_peserta_ujian_un: [{ required: true }],
        bahasa_indonesia: [{ required: true }],
        matematika: [{ required: true }],
        ipa: [{ required: true }],
        bahasa_inggris: [{ required: true }],
        rata_rata: [{ required: true }],
        jumlah_danem: [{ required: true }],
        ayah: {
          nama_lengkap: [{ required: true }],
          panggilan: [{ required: true }],
          nik: [
            { required: true },
            {
              pattern: /(^[0-9]*$)/,
              message: "Isi angka saja",
              trigger: "blur",
            },
            {
              min: 16,
              max: 16,
              message: "Panjang No NIK anda kurang",
              trigger: "blur",
            },
          ],
          tempat_lahir: [{ required: true }],
          tanggal_lahir: [{ required: true }],
          hubungan_ayah: [{ required: true }],
          status_ayah: [{ required: true }],
          alamat_lengkap: [{ required: true }],
          rt: [{ required: true }],
          rw: [{ required: true }],
          desa_kelurahan: [{ required: true }],
          kecamatan: [{ required: true }],
          kota_kabupaten: [{ required: true }],
          propinsi: [{ required: true }],
          kode_pos: [{ required: true }],
          telepon: [{ required: true }],
          pendidikan_terakhir: [{ required: true }],
          pekerjaan: [{ required: true }],
          penghasilan: [{ required: true }],
        },
        ibu: {
          nama_lengkap: [{ required: true }],
          panggilan: [{ required: true }],
          nik: [
            { required: true },
            {
              pattern: /(^[0-9]*$)/,
              message: "Isi angka saja",
              trigger: "blur",
            },
            {
              min: 16,
              max: 16,
              message: "Panjang No NIK anda kurang",
              trigger: "blur",
            },
          ],
          tempat_lahir: [{ required: true }],
          tanggal_lahir: [{ required: true }],
          hubungan_ibu: [{ required: true }],
          status_ibu: [{ required: true }],
          telepon: [{ required: true }],
          pendidikan_terakhir: [{ required: true }],
          pekerjaan: [{ required: true }],
        },
        wali: {
          nik: [
            {
              pattern: /(^[0-9]*$)/,
              message: "Isi angka saja",
              trigger: "blur",
            },
            {
              min: 16,
              max: 16,
              message: "Panjang No KTP anda kurang",
              trigger: "blur",
            },
          ],
        },
      },
      step_total: 2,
      step_active: 0,
      lembaga: [],
      success: false,
      successData: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    dayjs,
    initialData() {
      (this.preview = {
        foto: this.initial.file_foto,
        kk: this.initial.file_kk,
        akte: this.initial.file_akte,
      }),
        (this.form = {
          nisn: this.initial.nisn,
          siswa: this.initial.status_siswa,
          lembaga_id: this.initial.lembaga_id,
          kebutuhan_khusus: this.initial.kebutuhan_khusus,
          cita_cita: this.initial.cita_cita,
          hobi: this.initial.hobi,
          foto: null,
          no_jamkes: this.initial.no_jamkesmas,
          no_bpjs: this.initial.no_bpjs,
          no_kip: this.initial.no_kip,
          saudara: parseInt(this.initial.jml_anak),
          anak_ke: parseInt(this.initial.anak_ke),
          akte_kelahiran: null,
          status_keluarga: this.initial.status_anak,
          jenis_kelamin: this.initial.jenis_kelamin,
          tanggal_lahir: this.initial.tanggal_lahir,
          tempat_lahir: this.initial.tempat_lahir,
          kk: null,
          akte: null,
          no_kartu_keluarga: this.initial.no_kk,
          no_induk_kependudukan: this.initial.nik,
          nama_panggilan: this.initial.panggilan,
          nama_lengkap: this.initial.nama,
          jenis_sekolah: this.initial.sekolah_asal_jenis,
          nama_sekolah: this.initial.sekolah_asal_nama,
          status: this.initial.sekolah_asal_status,
          alamat_sekolah: this.initial.sekolah_asal_alamat,
          kota_kabupaten: this.initial.sekolah_asal_kota,
          nss_nsm: this.initial.sekolah_asal_nsm,
          npsn_sekolah: this.initial.sekolah_asal_npsn,
          seri_ijazah: this.initial.sekolah_asal_no_seri_ijazah,
          seri_shun_skhun: this.initial.sekolah_asal_no_skhun,
          no_peserta_ujian_un: this.initial.sekolah_asal_no_un,
          bahasa_indonesia: this.initial.sekolah_asal_nilai_bi,
          matematika: this.initial.sekolah_asal_nilai_mtk,
          ipa: this.initial.sekolah_asal_nilai_ipa,
          bahasa_inggris: this.initial.sekolah_asal_nilai_bing,
          rata_rata: this.initial.sekolah_asal_nilai_rata,
          jumlah_danem: this.initial.sekolah_asal_nilai_danem,
          ayah: {
            nama_lengkap: this.initial.nama_ayah,
            panggilan: this.initial.panggilan_ayah,
            nik: this.initial.nik_ayah,
            tempat_lahir: this.initial.tempat_lahir_ayah,
            tanggal_lahir: this.initial.tanggal_lahir_ayah,
            hubungan_ayah: this.initial.kondisi_ayah,
            status_ayah: this.initial.status_ayah,
            alamat_lengkap: this.initial.alamat_ayah,
            rt: this.initial.rt_ayah,
            rw: this.initial.rw_ayah,
            desa_kelurahan: this.initial.desa_ayah,
            kecamatan: this.initial.kecamatan_ayah,
            kota_kabupaten: this.initial.kota_ayah,
            propinsi: this.initial.propinsi_ayah,
            kode_pos: this.initial.kodepos_ayah,
            telepon: this.initial.hp_ayah,
            pendidikan_terakhir: this.initial.pendidikan_ayah,
            pekerjaan: this.initial.pekerjaan_ayah,
            penghasilan: this.initial.penghasilan_ayah,
          },
          ibu: {
            nama_lengkap: this.initial.nama_ibu,
            panggilan: this.initial.panggilan_ibu,
            nik: this.initial.nik_ibu,
            tempat_lahir: this.initial.tempat_lahir_ibu,
            tanggal_lahir: this.initial.tanggal_lahir_ibu,
            hubungan_ibu: this.initial.status_ibu,
            status_ibu: this.initial.kondisi_ibu,
            alamat_lengkap: this.initial.alamat_ibu,
            rt: this.initial.rt_ibu,
            rw: this.initial.rw_ibu,
            desa_kelurahan: this.initial.desa_ibu,
            kecamatan: this.initial.kecamatan_ibu,
            kota_kabupaten: this.initial.kota_ibu,
            propinsi: this.initial.propinsi_ibu,
            kode_pos: this.initial.kodepos_ibu,
            telepon: this.initial.hp_ibu,
            pendidikan_terakhir: this.initial.pendidikan_ibu,
            pekerjaan: this.initial.pekerjaan_ibu,
            penghasilan: this.initial.penghasilan_ibu,
          },
          wali: {
            nama_lengkap: this.initial.nama_wali,
            panggilan: this.initial.panggilan_wali,
            nik: this.initial.nik_wali,
            tempat_lahir: this.initial.tempat_lahir_wali,
            tanggal_lahir: this.initial.tanggal_lahir_wali,
            hubungan_wali: this.initial.status_wali,
            status_wali: this.initial.kondisi_wali,
            alamat_lengkap: this.initial.alamat_wali,
            rt: this.initial.rt_wali,
            rw: this.initial.rw_wali,
            desa_kelurahan: this.initial.desa_wali,
            kecamatan: this.initial.kecamatan_wali,
            kota_kabupaten: this.initial.kota_wali,
            propinsi: this.initial.propinsi_wali,
            kode_pos: this.initial.kodepos_wali,
            telepon: this.initial.hp_wali,
            pendidikan_terakhir: this.initial.pendidikan_wali,
            pekerjaan: this.initial.pekerjaan_wali,
            penghasilan: this.initial.penghasilan_wali,
          },
        });
    },
    formatRupiah(angka) {
      if (angka === null) {
        angka = 0;
      }
      if (angka !== undefined || angka !== null) {
        let numb = 0;
        let textVal1 = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(angka);
        let textVal2 = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(numb);
        let diff = textVal2.replace("0,00", "");
        return angka < 0
          ? textVal1.replace("-" + diff, "(Rp ") + ")"
          : textVal1;
      }
    },
    fileReader(file, type) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (type == "foto") {
          this.preview.foto = e.target.result;
        } else if (type == "kk") {
          this.preview.kk = e.target.result;
        } else {
          this.preview.akte = e.target.result;
        }
      };
    },
    async fetch() {
      await this.$axios
        .get(process.env.VUE_APP_BASE_API + "api/v1/ppdb/resource/lembaga")
        .then((response) => {
          this.lembaga = response.data.data;
          if (this.id) {
            this.$store.dispatch("detailRiwayat", {id: this.id, token: this.token}).then(res => {
              this.initialData();
            })
          }
        });
    },
    handleNext() {
      if (this.step_active == 0) {
        this.$refs.muridForm.validate((valid) => {
          if (valid) {
            this.step_active = this.step_active + 1;
            this.$nextTick(() => {
              this.$refs.content.scrollTop = 0;
            });
          } else {
            this.$notify.error({
              title: "Gagal Melanjutkan",
              message: "Lengkapi data anda terlebih dahulu!",
            });
          }
        });
      } else if (this.step_active == 1) {
        this.$refs.sekolahForm.validate((valid) => {
          if (valid) {
            this.step_active = this.step_active + 1;
            this.$nextTick(() => {
              this.$refs.content.scrollTop = 0;
            });
          } else {
            this.$notify.error({
              title: "Gagal Melanjutkan",
              message: "Lengkapi data anda terlebih dahulu!",
            });
          }
        });
      }
    },
    onPhotoSelected(file) {
      const image = this.fileReader(file.target.files[0], "foto");
      this.form.foto = file.target.files[0];
    },
    onKkSelected(file) {
      const image = this.fileReader(file.target.files[0], "kk");
      this.form.kk = file.target.files[0];
    },
    onAkteSelected(file) {
      const image = this.fileReader(file.target.files[0], "akte");
      this.form.akte = file.target.files[0];
    },
    handlePrevious() {
      this.step_active = this.step_active - 1;
    },
    handleJoin() {
      window.open(this.successData.group_wa);
    },
    handleDownload(item) {
      window.open(item, "_blank");
    },
    handleSubmit() {
      this.$refs.ortuForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = new FormData();
          data.append("lembaga_id", this.form.lembaga_id);
          data.append("nisn", this.form.nisn);
          data.append("status_siswa", this.form.siswa);
          data.append("nama", this.form.nama_lengkap);
          data.append("panggilan", this.form.nama_panggilan);
          data.append("nik", this.form.no_induk_kependudukan);
          data.append("no_kk", this.form.no_kartu_keluarga);
          data.append("tempat_lahir", this.form.tempat_lahir);
          data.append(
            "tanggal_lahir",
            this.dayjs(this.form.tanggal_lahir).format(this.formatdatedb)
          );
          data.append("jenis_kelamin", this.form.jenis_kelamin);
          data.append("status_anak", this.form.status_keluarga);
          data.append("anak_ke", this.form.anak_ke);
          data.append("jml_anak", this.form.saudara);
          data.append("no_kip", this.form.no_kip);
          data.append("no_bpjs", this.form.no_bpjs);
          data.append("no_jamkesmas", this.form.no_jamkes);
          data.append("hobi", this.form.hobi);
          data.append("cita_cita", this.form.cita_cita);
          data.append("kebutuhan_khusus", this.form.kebutuhan_khusus);
          if (this.id) {
            if (this.id && this.form.kk !== null) {
              data.append("kk", this.form.kk);
            }
            if (this.id && this.form.akte !== null) {
              data.append("akte", this.form.akte);
            }
            if (this.id && this.form.foto !== null) {
              data.append("foto", this.form.foto);
            }
          } else {
            data.append("kk", this.form.kk);
            data.append("akte", this.form.akte);
            data.append("foto", this.form.foto);
          }
          data.append("sekolah_asal_jenis", this.form.jenis_sekolah);
          data.append("sekolah_asal_nama", this.form.nama_sekolah);
          data.append("sekolah_asal_status", this.form.status);
          data.append("sekolah_asal_alamat", this.form.alamat_sekolah);
          data.append("sekolah_asal_kota", this.form.kota_kabupaten);
          data.append("sekolah_asal_no_seri_ijazah", this.form.seri_ijazah);
          data.append("sekolah_asal_nsm", this.form.nss_nsm);
          data.append("sekolah_asal_npsn", this.form.npsn_sekolah);
          data.append("sekolah_asal_no_skhun", this.form.seri_shun_skhun);
          data.append("sekolah_asal_nilai_bi", this.form.bahasa_indonesia);
          data.append("sekolah_asal_nilai_mtk", this.form.matematika);
          data.append("sekolah_asal_nilai_ipa", this.form.ipa);
          data.append("sekolah_asal_nilai_bing", this.form.bahasa_inggris);
          data.append("nama_ayah", this.form.ayah.nama_lengkap);
          data.append("panggilan_ayah", this.form.ayah.panggilan);
          data.append("nik_ayah", this.form.ayah.nik);
          data.append("tempat_lahir_ayah", this.form.ayah.tempat_lahir);
          data.append(
            "tanggal_lahir_ayah",
            this.dayjs(this.form.ayah.tanggal_lahir).format(this.formatdatedb)
          );
          data.append("status_ayah", this.form.ayah.status_ayah);
          data.append("kondisi_ayah", this.form.ayah.hubungan_ayah);
          data.append("alamat_ayah", this.form.ayah.alamat_lengkap);
          data.append("rt_ayah", this.form.ayah.rt);
          data.append("rw_ayah", this.form.ayah.rw);
          data.append("desa_ayah", this.form.ayah.desa_kelurahan);
          data.append("kecamatan_ayah", this.form.ayah.kecamatan);
          data.append("kota_ayah", this.form.ayah.kota_kabupaten);
          data.append("propinsi_ayah", this.form.ayah.propinsi);
          data.append("kodepos_ayah", this.form.ayah.kode_pos);
          data.append("hp_ayah", this.form.ayah.telepon);
          data.append("pendidikan_ayah", this.form.ayah.pendidikan_terakhir);
          data.append("pekerjaan_ayah", this.form.ayah.pekerjaan);
          data.append("penghasilan_ayah", this.form.ayah.penghasilan);
          data.append("nama_ibu", this.form.ibu.nama_lengkap);
          data.append("panggilan_ibu", this.form.ibu.panggilan);
          data.append("nik_ibu", this.form.ibu.nik);
          data.append("tempat_lahir_ibu", this.form.ibu.tempat_lahir);
          data.append(
            "tanggal_lahir_ibu",
            this.dayjs(this.form.ibu.tanggal_lahir).format(this.formatdatedb)
          );
          data.append("status_ibu", this.form.ibu.hubungan_ibu);
          data.append("kondisi_ibu", this.form.ibu.status_ibu);
          data.append("alamat_ibu", this.form.ibu.alamat_lengkap);
          data.append("rt_ibu", this.form.ibu.rt);
          data.append("rw_ibu", this.form.ibu.rw);
          data.append("desa_ibu", this.form.ibu.desa_kelurahan);
          data.append("kecamatan_ibu", this.form.ibu.kecamatan);
          data.append("kota_ibu", this.form.ibu.kota_kabupaten);
          data.append("propinsi_ibu", this.form.ibu.propinsi);
          data.append("kodepos_ibu", this.form.ibu.kode_pos);
          data.append("hp_ibu", this.form.ibu.telepon);
          data.append("pendidikan_ibu", this.form.ibu.pendidikan_terakhir);
          data.append("pekerjaan_ibu", this.form.ibu.pekerjaan);
          data.append("penghasilan_ibu", this.form.ibu.penghasilan);
          data.append("nama_wali", this.form.wali.nama_lengkap);
          data.append("panggilan_wali", this.form.wali.panggilan);
          data.append("nik_wali", this.form.wali.nik);
          data.append("tempat_lahir_wali", this.form.wali.tempat_lahir);
          if (this.form.wali.tanggal_lahir) {
            data.append(
              "tanggal_lahir_wali",
              this.dayjs(this.form.wali.tanggal_lahir).format(this.formatdatedb)
            );
          }
          data.append("status_wali", this.form.wali.hubungan_wali);
          data.append("kondisi_wali", this.form.wali.status_wali);
          data.append("alamat_wali", this.form.wali.alamat_lengkap);
          data.append("rt_wali", this.form.wali.rt);
          data.append("rw_wali", this.form.wali.rw);
          data.append("desa_wali", this.form.wali.desa_kelurahan);
          data.append("kecamatan_wali", this.form.wali.kecamatan);
          data.append("kota_wali", this.form.wali.kota_kabupaten);
          data.append("propinsi_wali", this.form.wali.propinsi);
          data.append("kodepos_wali", this.form.wali.kode_pos);
          data.append("hp_wali", this.form.wali.telepon);
          data.append("pendidikan_wali", this.form.wali.pendidikan_terakhir);
          data.append("pekerjaan_wali", this.form.wali.pekerjaan);
          data.append("penghasilan_wali", this.form.wali.penghasilan);

          let url = process.env.VUE_APP_BASE_API + "api/v1/ppdb/pendaftaran";
          let method = "post";
          if (this.id) {
            url =
              process.env.VUE_APP_BASE_API +
              `api/v1/ppdb/pendaftaran/${this.id}`;
            method = "post";
          }

          this.$axios({
            method: method,
            url: url,
            data: data,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.token,
            },
          })
            .then((response) => {
              if (this.id) {
                this.$notify({
                  title: "Success",
                  message: "Perbaruan data berhasil!",
                  type: "success",
                });
                this.$router.push({name: 'Riwayat'})
              } else {
                this.successData = response.data.data;
                this.success = true;
              }
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Gagal Registrasi",
            message: "Lengkapi data anda terlebih dahulu!",
          });
        }
      });
    },
    handleBySiswa() {
      console.log(this.form.siswa);
      console.log(this.lembaga);
      if (this.form.siswa == "lama") {
        this.lembagaBySiswa = this.lembaga.filter((obj) => obj.value == "8");
      } else {
        console.log(this.lembaga);
        this.lembagaBySiswa = this.lembaga;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background-color: darken($color: white, $amount: 5);
}
.content {
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  flex: 1;
  padding: $space * 2 4% $space * 2 4% !important;
  border: 1px solid darken(white, 10);
  @media #{$mq-xlarge} {
    padding: $space * 2 18% $space * 2 18% !important;
  }
}

.step {
  border-bottom: 1px solid darken(white, 15%);
  background-color: darken(white, 10);

  &.mobile {
    height: 90px;
    padding: $space;
  }

  &-wrapper {
    border: 1px solid darken(white, 20%);
    border-top: 2px solid $green-color;
    border-top-right-radius: $space / 2;
    border-top-left-radius: $space / 2;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &-content {
    padding: $space * 2 12%;
    overflow: auto;
    flex: 1;
  }

  &-footer {
    border-top: 1px solid darken(white, 10);
    background-color: darken(white, 15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    padding: 0 12%;
    flex-direction: row-reverse;
  }
}

.form {
  &-section-title {
    position: relative;
    padding-bottom: $space;
    &:after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      height: 3px;
      width: 45px;
      background-color: orangered;
    }
  }
}

.upload-wrapper {
  > input {
    display: none;
  }
  > .el-button {
    width: 100%;
  }
}

.image {
  &-wrapper {
    height: 140px;
    width: 100%;
    border: 1px solid darken(white, 10);
    margin-bottom: $space / 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-slot {
    i {
      font-size: 30px;
    }
  }
}
.card {
  width: 500px;
  text-align: center;
  // margin-top: 110px;
}
.list-info {
  padding: 0;
  text-align: left;
  li {
    border: 1px solid darken(white, 10);
    list-style: none;
    padding: $space;
    h4 {
      margin: 0;
    }
  }
}

.section {
  &-title {
    display: flex;
    align-items: flex-start;
    gap: $space;

    h4 {
      margin-top: 0;
    }
  }
}
</style>
